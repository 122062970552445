import React from 'react';
import {
   makeStyles, Grid, Container, Box, Typography, Button,
} from '@material-ui/core';

// Components
import TopBarRenascimento from '../../components/TopBarRenascimento';
import Footer from '../../components/Footer';
import { Icon } from '@iconify/react';

// Images
import Bg from '../../images/dease-header.webp';
import Bg2 from '../../images/dease-bg-2.webp';
import Drica from '../../images/professor-drica-2.webp';
import Cobra from '../../images/professor-cobra-3.webp';
import Bandeira from '../../images/professor-bandeira-2.webp';
// import Bruna from '../../images/professor-bruna.webp';

const useStyles = makeStyles((theme) => ({
   // Globals
   root: {
      backgroundColor: theme.palette.secondary.dark,
      overflowX: 'hidden',
   },
   section: {
      position: 'relative',
      width: '100%',
      height: '100%',
      maxHeight: '720px',
      backgroundImage: `url('${Bg}')`,
      backgroundSize: 'cover',
      backgroundPosition: 'center top',
      [theme.breakpoints.down('sm')]: {
         height: 'auto',
      },
   },
   header: {
      width: '100%',
      height: '100vh',
      maxHeight: '650px',
      // [theme.breakpoints.down('sm')]: {
      //    paddingTop: '8rem',
      // }
   },
   primary: {
      color: theme.palette.primary.main,
   },
   secondary: {
      color: "#F61D12",
   },
   white: {
      color: theme.palette.common.white,
   },
   button: {
      color: theme.palette.primary.contrastText,
      boxShadow: 'none',
      borderRadius: '8px',
      padding: '0.5rem 2.5rem',
      fontSize: '1rem',
      fontWeight: '700',
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
         boxShadow: '0px 6px 30px rgba(240, 201, 0, 0.6) !important',
         backgroundColor: theme.palette.primary.main,
      },
   },
   buttonLabel: {
      textTransform: 'none !important',
   },
   relative: {
      position: 'relative',
   },

   // Seja bem vindo
   bg2: {
      width: 'auto',
      height: 'auto',
      maxHeight: '600px',
      backgroundImage: `url('${Bg2}')`,
      backgroundSize: 'cover',
      backgroundPosition: 'left center',
      [theme.breakpoints.down('sm')]: {
         maxHeight: '100%',
      }
   },
   iconArrowDown: {
      textAlign: 'center',
      color: theme.palette.primary.main,
      fontSize: '1.5rem',
      marginTop: '0rem',
      marginRight: '1rem',
      marginBottom: '1rem',
      [theme.breakpoints.down('sm')]: {
         marginBottom: '2rem',
      }
   },

   // O que faz um policial civil?
   icon: {
      float: 'left',
      color: theme.palette.primary.main,
      fontSize: '1.75rem',
      marginRight: '1rem',
   },

   // FAQ
   accordion: {
      padding: '0.5rem',
      backgroundColor: `#13151E !important`,
      borderRadius: '8px !important',
      marginBottom: '1.5rem',
      border: 'none !important',
      boxShadow: 'none !important',
      '&::before': {
         backgroundColor: 'transparent !important',
      }
   },
   h1: {
      color: theme.palette.common.white,
      fontSize: '4rem',
   },
   iconFire: {
      color: theme.palette.common.white,
      fontSize: '1.5rem',
      display: 'inline',
      lineHeight: '2rem',
      margin: '0 5px',
      [theme.breakpoints.down('sm')]: {
      }
   },

}));

export default function Renascimento() {
   const classes = useStyles();

   return (
      <Grid className={classes.root}>

         <TopBarRenascimento />

         {/* Header */}
         <Grid className={classes.section}>
            <Container>
               <Grid className={classes.header} container justifyContent='center' alignItems='center'>
                  <Grid item md='5'>
                     <Box mt={{ xs: 6, md: 54 }} pt={{ xs: 0, md: 78 }} pb={{ xs: 12, md: 0 }} pl={{ xs: 4, md: 0 }} pr={{ xs: 4, md: 0 }} textAlign='center'>
                        <Box mb={4}>
                           <Typography component='h1' variant='h1' className={classes.h1}>
                              TURMA VIP DEASE/SC
                           </Typography>
                        </Box>
                        <Box mb={8}>
                           <Typography component='p' variant='body1' className={classes.white}>
                              com Ronaldo bandeira
                           </Typography>
                        </Box>
                        <Box textAlign='center'>
                           <Icon icon="material-symbols:arrow-circle-down-outline-rounded" className={classes.iconArrowDown} />
                        </Box>
                     </Box>
                  </Grid>
               </Grid>
            </Container>
         </Grid>

         {/* O que Oferecemos nesta Turma */}
         <Box pt={{ xs: 18, md: 30 }} px={{ xs: 4, md: 0 }} pb={{ xs: 4, md: 30 }} mt={{ xs: 0, md: 20 }}>
            <Container className={classes.container}>
               <Grid className={classes.white} container justifyContent='space-around' alignItems='center' direction='column'>
                  <Grid item md='5'>
                     <Box mb={{ xs: 8 }} ml={{ md: 0 }} textAlign='center'>
                        <Typography component='h3' variant='h1'>
                           <span className={classes.secondary}>O que Oferecemos</span> nesta Turma
                        </Typography>
                     </Box>
                  </Grid>
                  <Grid item md='6'>
                     {/* <Box mb={{ xs: 4, md: 4 }}> */}
                     <Grid container alignContent='center' justifyContent='center' direction='column'>
                        <Grid item xs={12} md={8}>
                           <Box className={classes.listItem} p={3} textAlign='left'>
                              <Icon icon="fluent:flash-28-regular" className={classes.icon} />
                              <Typography variant='body2' component='span' className={classes.listItemWhite}>
                                 Reta Final (Pós edital - Aulas ao vivo transmitidas em tempo real – ficando gravadas)
                              </Typography>
                           </Box>
                           <Box className={classes.listItem} p={3} textAlign='left'>
                              <Icon icon="fluent:flash-28-regular" className={classes.icon} />
                              <Typography variant='body2' component='span' className={classes.listItemWhite}>
                                 Teoria (Aulas Teóricas em Estúdio)
                              </Typography>
                           </Box>
                           <Box className={classes.listItem} p={3} textAlign='left'>
                              <Icon icon="fluent:flash-28-regular" className={classes.icon} />
                              <Typography variant='body2' component='span' className={classes.listItemWhite}>
                                 Exercícios (Aulas Teóricas em Estúdio)
                              </Typography>
                           </Box>
                           <Box className={classes.listItem} p={3} textAlign='left'>
                              <Icon icon="fluent:flash-28-regular" className={classes.icon} />
                              <Typography variant='body2' component='span' className={classes.listItemWhite}>
                                 1 Aula de TAF Presencial em Itajaí (Pista de Atletismo)
                              </Typography>
                           </Box>
                           <Box className={classes.listItem} p={3} textAlign='left'>
                              <Icon icon="fluent:flash-28-regular" className={classes.icon} />
                              <Typography variant='body2' component='span' className={classes.listItemWhite}>
                                 2 Simulados (Pós Edital)
                              </Typography>
                           </Box>
                           {/* <Box className={classes.listItem} p={3} textAlign='left'> */}
                              {/* <Icon icon="fluent:flash-28-regular" className={classes.icon} /> */}
                              {/* <Typography variant='body2' component='span' className={classes.listItemWhite}> */}
                                 {/* Mentoria quinzenal com Ronaldo Bandeira ao vivo para tira dúvidas */}
                              {/* </Typography> */}
                           {/* </Box> */}
                        </Grid>
                     </Grid>
                     {/* </Box> */}
                  </Grid>
               </Grid>

               {/* Nosso corpo docente... */}
               <Grid item md={12}>
                  <Box mt={{ xs: 12, md: 24 }} pb={{ xs: 0, md: 0 }} px={{ xs: 4, md: 0 }}>
                     <Grid container justifyContent='space-between' alignItems='flex-start'>
                        <Grid item xs={12} md={12}>
                           <Box textAlign='center' mb={{ xs: 4, md: 10 }}>
                              <Typography component='h3' variant='h1' className={classes.white}>
                                 Nosso corpo docente <br /><span className={classes.secondary}> com os melhores professores:</span>
                              </Typography>
                           </Box>
                        </Grid>
                        <Grid container justifyContent='center' spacing={8}>
                           <Grid item md={3}>
                              <img src={Drica} width='100%' alt='' />
                           </Grid>
                           <Grid item md={3}>
                              <img src={Cobra} width='100%' alt='' />
                           </Grid>
                           <Grid item md={3}>
                              <img src={Bandeira} width='100%' alt='' />
                           </Grid>
                           {/* <Grid item md={3}>
                              <img src={Bruna} width='100%' alt='' />
                           </Grid> */}
                        </Grid>
                        {/* <Grid item xs={12} md={12}> */}
                        {/* <Box textAlign='center' mt={12}> */}
                        {/* <Typography component='h3' variant='h2' className={classes.white}> */}
                        {/* Montamos uma equipe dos sonhos com uma só missão: */}
                        {/* </Typography> */}
                        {/* <Typography component='h3' variant='h1' className={classes.primary}> */}
                        {/* A sua aprovação! <Icon icon="simple-line-icons:check" className={classes.iconFire} /> */}
                        {/* </Typography> */}
                        {/* </Box> */}
                        {/* </Grid> */}
                     </Grid>
                  </Box>
               </Grid>
            </Container>
         </Box>

         {/* O Novo RB ouviu você */}
         <Box className={classes.bg2} pt={{ xs: 10, md: 20 }} px={{ xs: 4, md: 0 }} pb={{ xs: 10, md: 20 }}>
            <Grid container justifyContent='center' spacing={8}>
               <Grid container justifyContent='center' alignItems='flex-start'>
                  <Grid item md={4} className={classes.white}>
                     <Box mb={{ xs: 8 }} textAlign='center'>
                        <Typography component='h2' variant='h2'>
                           Assista esse
                           <span className={classes.secondary}> vídeo </span><br />
                           para entender melhor
                        </Typography>
                     </Box>
                  </Grid>
                  <Grid item md={12}>
                     <Box mb={{ xs: 8, md: 12 }} textAlign='center'>
                        {/* eslint-disable-next-line */}
                        <iframe src="https://drive.google.com/file/d/1dESMuGXlofr8XVlzQeNvyWoaduMkj2p4/preview" width="720" height="400" allow="autoplay" frameborder="0"></iframe>
                     </Box>
                  </Grid>
               </Grid>
            </Grid>
         </Box>

         {/* Você tem um sonho... */}
         <Box pt={{ xs: 10, md: 20 }} px={{ xs: 4, md: 0 }} pb={{ xs: 10, md: 20 }}>
            <Container className={classes.container}>
               <Grid container justifyContent='center' alignItems='flex-start'>
                  <Grid item md={5} className={classes.white}>
                     <Box mb={{ xs: 8, md: 12 }} textAlign='center'>
                        <Typography component='h2' variant='h2'>
                           Aqui nesta Página você paga o menor preço, ou seja,
                           <span className={classes.secondary}> o preço de Black Friday</span>
                        </Typography>
                     </Box>
                     <Box mb={1} textAlign='center'>
                        <Grid container justifyContent='center' alignItems='center' alignContent='center' spacing={0}>
                           <Grid item>
                              <Icon icon="uil:fire" className={classes.iconFire} />
                           </Grid>
                           <Grid item>
                              <Typography component='span' variant='h1'>
                                 Só
                                 <span className={classes.primary}> R$ 19,70</span>
                              </Typography>
                           </Grid>
                           <Grid item>
                              <Icon icon="uil:fire" className={classes.iconFire} />
                           </Grid>
                        </Grid>
                     </Box>
                     {/* <Box mb={{ xs: 8, md: 8 }} textAlign='center'> */}
                        {/* <Typography component='p' variant='body1'> */}
                           {/* <span className={classes.secondary}>Tempo de acesso</span> – Investindo no seu sonho nesta página você terá um presente */}
                           {/* meu que será o acesso até a sua prova do DEASE/SC para garantir que, inclusive, */}
                           {/* caso haja alterações no próximo edital você terá direito */}
                        {/* </Typography> */}
                     {/* </Box> */}
                  </Grid>
                  <Grid item md={12}>
                     <Box mt={8} textAlign='center'>
                        <Button variant='contained' href='https://sun.eduzz.com/2175132'
                           classes={{
                              root: classes.button,
                              label: classes.buttonLabel,
                           }}>EU QUERO!
                        </Button>
                     </Box>
                  </Grid>
               </Grid>
            </Container>
         </Box>


         <Footer />

      </Grid>
   );
}