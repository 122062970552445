import React from 'react';
import {
   makeStyles, Grid, Container, Box, Typography, Button,
   List, ListItem, ListItemIcon, ListItemText
} from '@material-ui/core';

// Components
import TopBar from '../../components/TopBar';
import Footer from '../../components/Footer';
import { Icon } from '@iconify/react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Images
import Bg from '../../images/pcsc-bg.webp';
import Bg2 from '../../images/pcsc-bg-2.webp';
import Drica from '../../images/professor-drica.webp';
import Eleandra from '../../images/professor-eleandra.webp';
import Von from '../../images/professor-von.webp';

const useStyles = makeStyles((theme) => ({
   // Globals
   root: {
      backgroundColor: theme.palette.secondary.dark,
      overflowX: 'hidden',
   },
   section: {
      position: 'relative',
      width: '100%',
      height: '100%',
      // maxHeight: '720px',
      backgroundImage: `url('${Bg}')`,
      backgroundSize: 'cover',
      backgroundPosition: 'left center',
      [theme.breakpoints.down('sm')]: {
         height: 'auto',
      },
   },
   header: {
      width: '100%',
      height: '100vh',
      maxHeight: '650px',
      // [theme.breakpoints.down('sm')]: {
      //    paddingTop: '8rem',
      // }
   },
   primary: {
      color: theme.palette.primary.main,
   },
   secondary: {
      color: theme.palette.secondary.main,
   },
   white: {
      color: theme.palette.common.white,
   },
   button: {
      color: theme.palette.primary.contrastText,
      boxShadow: 'none',
      borderRadius: '8px',
      padding: '0.5rem 2.5rem',
      fontSize: '1rem',
      fontWeight: '700',
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
         boxShadow: '0px 6px 30px rgba(240, 201, 0, 0.6) !important',
         backgroundColor: theme.palette.primary.main,
      },
   },
   buttonLabel: {
      textTransform: 'none !important',
   },
   relative: {
      position: 'relative',
   },

   // Seja bem vindo
   bg2: {
      width: 'auto',
      height: 'auto',
      maxHeight: '600px',
      backgroundImage: `url('${Bg2}')`,
      backgroundSize: 'cover',
      backgroundPosition: 'left center',
      [theme.breakpoints.down('sm')]: {
         maxHeight: '100%',
      }
   },
   iconArrowDown: {
      textAlign: 'center',
      color: theme.palette.primary.main,
      fontSize: '1.5rem',
      marginTop: '0rem',
      marginRight: '1rem',
      marginBottom: '1rem',
      [theme.breakpoints.down('sm')]: {
         marginBottom: '2rem',
      }
   },

   // O que faz um policial civil?
   icon: {
      float: 'left',
      color: theme.palette.primary.main,
      fontSize: '1.75rem',
      marginRight: '1rem',
   },

   // FAQ
   accordion: {
      padding: '0.5rem',
      backgroundColor: `#13151E !important`,
      borderRadius: '8px !important',
      marginBottom: '1.5rem',
      border: 'none !important',
      boxShadow: 'none !important',
      '&::before': {
         backgroundColor: 'transparent !important',
      }
   },

}));

export default function Pcsc() {
   const classes = useStyles();

   return (
      <Grid className={classes.root}>

         <TopBar />

         {/* Header */}
         <Grid className={classes.section}>
            <Container>
               <Grid className={classes.header} container justifyContent='flex-start' alignItems='center'>
                  <Grid item md='6'>
                     <Box mt={{ xs: 6, md: 0 }} pt={{ xs: 0, md: 8 }} pb={{ xs: 12, md: 0 }} pl={{ xs: 4, md: 0 }} pr={{ xs: 4, md: 14 }} textAlign='left'>
                        {/* <Box mb={4}>
                        <Typography component='sup' variant='body2' className={classes.sup}>
                           Abertura do Último Vitalício do RB Carreiras Policiais
                        </Typography>
                     </Box> */}
                        <Box mb={4}>
                           <Typography component='h1' variant='h1' className={classes.white}>
                              Curso da <div className={classes.primary}>Polícia Civil de Santa Catarina </div>
                           </Typography>
                        </Box>
                        <Box mb={8}>
                           <Typography component='p' variant='h4' className={classes.white}>
                              {/* <span className={classes.secondary}>De R$ 497</span> -  */}
                              {/* <span className={classes.primary}>Por R$ 347,90</span> */}
                              1 ano de acesso por R$ 157,00
                           </Typography>
                        </Box>
                        <Box mb={4}>
                           <Button variant='contained' href='https://sun.eduzz.com/2138686'
                              classes={{
                                 root: classes.button,
                                 label: classes.buttonLabel,
                              }}>Comprar agora
                           </Button>
                        </Box>
                     </Box>
                  </Grid>
                  <Grid item md='5'></Grid>
               </Grid>
            </Container>
         </Grid>

         {/* Seja bem vindo... */}
         <Box className={classes.bg2} pt={{ xs: 10, md: 20 }} px={{ xs: 4, md: 0 }} pb={{ xs: 10, md: 20 }}>
            <Container className={classes.container}>
               <Grid container justifyContent='center' alignItems='flex-start'>
                  <Grid item md={5} className={classes.white}>
                     <Box mb={{ xs: 8 }} textAlign='center'>
                        <Typography component='h2' variant='h2'>
                           <span className={classes.primary}>SEJA BEM VINDO(A) </span>
                           AO CURSO QUE É REFERÊNCIA NACIONAL EM APROVAÇÕES.
                        </Typography>
                     </Box>
                     <Box mb={{ xs: 8, md: 12 }} textAlign='center'>
                        <Typography component='p' variant='body1'>
                           Conheça nosso combo até a data da prova na Polícia Civil de Santa Catarina.
                           Ferramentas únicas desenvolvidas para alavancar seus estudos e garantir sua aprovação.
                        </Typography>
                     </Box>
                     <Box textAlign='center'>
                        <Icon icon="material-symbols:arrow-circle-down-outline-rounded" className={classes.iconArrowDown} />
                     </Box>
                  </Grid>
               </Grid>
            </Container>
         </Box>

         {/* O que faz um policial civil? */}
         <Box py={{ xs: 4, md: 16 }}>
            <Grid container justifyContent='center' spacing={8}>
               <Grid container justifyContent='center' alignItems='flex-start'>
                  <Grid item md={5} className={classes.white}>
                     <Box mb={{ xs: 8 }} textAlign='center'>
                        <Typography component='h2' variant='h2'>
                           <span className={classes.primary}>O que faz </span>
                           um policial civil?
                        </Typography>
                     </Box>
                     <Box mb={{ xs: 8, md: 12 }} textAlign='center'>
                        <Typography component='p' variant='body1'>
                           Segundo o site da Polícia Civil, a Polícia Civil do Estado de Santa Catarina
                           possui como atribuições constitucionais, previstas no Artigo 144, § 4º da
                           Constituição Federal, e Artigo 106 da Constituição Estadual, entre outras:
                        </Typography>
                     </Box>
                  </Grid>
               </Grid>
               <Grid item md={8}>
                  <Grid container justifyContent='space-around' alignItems='flex-start' spacing={8}>
                     <Grid item md={5} className={classes.white}>
                        <Box mb={{ xs: 4, md: 4 }}>
                           <List>
                              <ListItem alignItems="flex-start">
                                 <ListItemIcon>
                                    <Icon icon="mdi:police-badge-outline" className={classes.icon} />
                                 </ListItemIcon>
                                 <ListItemText>
                                    <Typography variant='body2' component='span' className={classes.white}>
                                       Exercer as atividades de polícia judiciária e apurar as infrações penais no âmbito do território estadual, na forma da legislação em vigor;
                                    </Typography>
                                 </ListItemText>
                              </ListItem>
                              <ListItem alignItems="flex-start">
                                 <ListItemIcon>
                                    <Icon icon="mdi:police-badge-outline" className={classes.icon} />
                                 </ListItemIcon>
                                 <ListItemText>
                                    <Typography variant='body2' component='span' className={classes.white}>
                                       Promover as perícias criminais e médico-legais necessárias, requisitando-as aos órgãos competentes;
                                    </Typography>
                                 </ListItemText>
                              </ListItem>
                              <ListItem alignItems="flex-start">
                                 <ListItemIcon>
                                    <Icon icon="mdi:police-badge-outline" className={classes.icon} />
                                 </ListItemIcon>
                                 <ListItemText>
                                    <Typography variant='body2' component='span' className={classes.white}>
                                       Participar dos Sistemas Nacionais de Identificação Criminal, de Armas e Explosivos, de Roubos e Furtos de Veículos Automotores, informação e Inteligência, e de outros, no âmbito da Segurança Pública;
                                    </Typography>
                                 </ListItemText>
                              </ListItem>
                           </List>
                        </Box>
                     </Grid>
                     <Grid item md={5} className={classes.white}>
                        <Box mb={{ xs: 4, md: 4 }}>
                           <List>
                              <ListItem alignItems="flex-start">
                                 <ListItemIcon>
                                    <Icon icon="mdi:police-badge-outline" className={classes.icon} />
                                 </ListItemIcon>
                                 <ListItemText>
                                    <Typography variant='body2' component='span' className={classes.white}>
                                       Manter serviço diuturno de atendimento aos cidadãos;
                                    </Typography>
                                 </ListItemText>
                              </ListItem>
                              <ListItem alignItems="flex-start">
                                 <ListItemIcon>
                                    <Icon icon="mdi:police-badge-outline" className={classes.icon} />
                                 </ListItemIcon>
                                 <ListItemText>
                                    <Typography variant='body2' component='span' className={classes.white}>
                                       Reprimir as infrações penais;
                                    </Typography>
                                 </ListItemText>
                              </ListItem>
                              <ListItem alignItems="flex-start">
                                 <ListItemIcon>
                                    <Icon icon="mdi:police-badge-outline" className={classes.icon} />
                                 </ListItemIcon>
                                 <ListItemText>
                                    <Typography variant='body2' component='span' className={classes.white}>
                                       Proteger pessoas e bens e os direitos e garantias individuais;
                                    </Typography>
                                 </ListItemText>
                              </ListItem>
                              <ListItem alignItems="flex-start">
                                 <ListItemIcon>
                                    <Icon icon="mdi:police-badge-outline" className={classes.icon} />
                                 </ListItemIcon>
                                 <ListItemText>
                                    <Typography variant='body2' component='span' className={classes.white}>
                                       Custodiar provisoriamente pessoas presas, nos limites de sua competência;
                                    </Typography>
                                 </ListItemText>
                              </ListItem>
                              <ListItem alignItems="flex-start">
                                 <ListItemIcon>
                                    <Icon icon="mdi:police-badge-outline" className={classes.icon} />
                                 </ListItemIcon>
                                 <ListItemText>
                                    <Typography variant='body2' component='span' className={classes.white}>
                                       A fiscalização de jogos e diversões públicas.
                                    </Typography>
                                 </ListItemText>
                              </ListItem>
                           </List>
                        </Box>
                     </Grid>
                  </Grid>
               </Grid>
            </Grid>
         </Box>

         {/* QUAL SALÁRIO A POLÍCIA CIVIL DE SANTA CATARINA? */}
         <Box className={classes.bg2} pt={{ xs: 10, md: 20 }} px={{ xs: 4, md: 0 }} pb={{ xs: 10, md: 20 }}>
            <Container className={classes.container}>
               <Grid container justifyContent='center' alignItems='flex-start'>
                  <Grid item md={5} className={classes.white}>
                     <Box mb={{ xs: 8, md: 12 }} textAlign='center'>
                        <Typography component='h2' variant='h2'>
                           <span className={classes.primary}>QUAL SALÁRIO </span>
                           DA POLÍCIA CIVIL DE SANTA CATARINA?
                        </Typography>
                     </Box>
                     <Box mb={{ xs: 8, md: 8 }} textAlign='center'>
                        <Typography component='p' variant='body1'>
                           Atualmente o Policial na carreira de Agente da Autoridade Policial Classe I
                           (Agente, Escrivão e Psicólogo) recebe salário no valor de
                           <span className={classes.primary}> R$ 5.290,95.</span>
                        </Typography>
                     </Box>
                     <Box mb={{ xs: 8, md: 8 }} textAlign='center'>
                        <Typography component='p' variant='body1'>
                           Já o final da carreira (correspondente a carreira de Agente da Autoridade
                           Policial Classe VIII) será de <span className={classes.primary}>R$ 14.529,10.</span>
                        </Typography>
                     </Box>
                  </Grid>
               </Grid>
            </Container>
         </Box>

         {/* VANTAGENS DO ATÉ A PCSC */}
         <Box py={{ xs: 4, md: 16 }}>
            <Grid container justifyContent='center' spacing={8}>
               <Grid container justifyContent='center' alignItems='flex-start'>
                  <Grid item md={5} className={classes.white}>
                     <Box mb={{ xs: 8 }} textAlign='center'>
                        <Typography component='h2' variant='h2'>
                           <span className={classes.primary}>VANTAGENS </span>
                           DO CURSO DA PCSC
                        </Typography>
                     </Box>
                  </Grid>
               </Grid>
               <Grid item md={8}>
                  <Grid container justifyContent='space-around' alignItems='flex-start' spacing={8}>
                     <Grid item md={4} className={classes.white}>
                        <Box mb={{ xs: 4, md: 0 }}>
                           <List>
                              <ListItem alignItems="flex-start">
                                 <ListItemIcon>
                                    <Icon icon="ph:star-light" className={classes.icon} />
                                 </ListItemIcon>
                                 <ListItemText>
                                    <Typography variant='body2' component='span' className={classes.white}>
                                       Desafio da PCSC com 90 metas diárias para alavancar seus estudos
                                    </Typography>
                                 </ListItemText>
                              </ListItem>
                              <ListItem alignItems="flex-start">
                                 <ListItemIcon>
                                    <Icon icon="ph:star-light" className={classes.icon} />
                                 </ListItemIcon>
                                 <ListItemText>
                                    <Typography variant='body2' component='span' className={classes.white}>
                                       Materiais Complementares: 50 questões
                                    </Typography>
                                 </ListItemText>
                              </ListItem>
                           </List>
                        </Box>
                     </Grid>
                     <Grid item md={4} className={classes.white}>
                        <Box mb={{ xs: 4, md: 0 }}>
                           <List>
                              <ListItem alignItems="flex-start">
                                 <ListItemIcon>
                                    <Icon icon="ph:star-light" className={classes.icon} />
                                 </ListItemIcon>
                                 <ListItemText>
                                    <Typography variant='body2' component='span' className={classes.white}>
                                       Curso teórico completo gravado com os melhores professores
                                    </Typography>
                                 </ListItemText>
                              </ListItem>
                              <ListItem alignItems="flex-start">
                                 <ListItemIcon>
                                    <Icon icon="ph:star-light" className={classes.icon} />
                                 </ListItemIcon>
                                 <ListItemText>
                                    <Typography variant='body2' component='span' className={classes.white}>
                                       Cronograma de estudos pré edital
                                    </Typography>
                                 </ListItemText>
                              </ListItem>
                           </List>
                        </Box>
                     </Grid>
                     <Grid item md={4} className={classes.white}>
                        <Box mb={{ xs: 4, md: 0 }}>
                           <List>
                              <ListItem alignItems="flex-start">
                                 <ListItemIcon>
                                    <Icon icon="ph:star-light" className={classes.icon} />
                                 </ListItemIcon>
                                 <ListItemText>
                                    <Typography variant='body2' component='span' className={classes.white}>
                                       Presencial na sua casa: Aulas gravadas durante o presencial de 2022
                                    </Typography>
                                 </ListItemText>
                              </ListItem>
                              <ListItem alignItems="flex-start">
                                 <ListItemIcon>
                                    <Icon icon="ph:star-light" className={classes.icon} />
                                 </ListItemIcon>
                                 <ListItemText>
                                    <Typography variant='body2' component='span' className={classes.white}>
                                       Rotina de estudos pré edital
                                    </Typography>
                                 </ListItemText>
                              </ListItem>
                           </List>
                        </Box>
                     </Grid>
                  </Grid>
               </Grid>
               <Grid item md={12}>
                  <Box mt={8} textAlign='center'>
                     <Button variant='contained' href='https://sun.eduzz.com/2138686'
                        classes={{
                           root: classes.button,
                           label: classes.buttonLabel,
                        }}>Comprar agora
                     </Button>
                  </Box>
               </Grid>
            </Grid>
         </Box>

         {/* Acesso online ao Presencial... */}
         <Box mt={{ xs: 12, md: 16 }} pb={{ xs: 0, md: 0 }} px={{ xs: 4, md: 0 }}>
            <Container>
               <Grid container justifyContent='space-between' alignItems='flex-start'>
                  <Grid item xs={12} md={12}>
                     <Box textAlign='center' mb={{ xs: 4, md: 10 }}>
                        <Typography component='h3' variant='h2' className={classes.white}>
                           <span className={classes.primary}>Professores</span> do curso
                        </Typography>
                     </Box>
                  </Grid>
                  <Grid container justifyContent='center' spacing={8}>
                     <Grid item md={3}>
                        <img src={Drica} width='100%' alt='' />
                     </Grid>
                     <Grid item md={3}>
                        <img src={Eleandra} width='100%' alt='' />
                     </Grid>
                     <Grid item md={3}>
                        <img src={Von} width='100%' alt='' />
                     </Grid>
                  </Grid>
               </Grid>
            </Container>
         </Box>

         {/* Perguntas frequentes */}
         <Box pt={{ xs: 18, md: 40 }} mb={{ xs: 4, md: 20 }} px={{ xs: 4, md: 0 }}>
            <Container className={classes.container}>
               <Grid container className={classes.white} justifyContent='space-around' alignItems='center' direction='column'>
                  <Grid item md='8'>
                     <Box mb={{ xs: 12 }} textAlign='center'>
                        <Typography component='h3' variant='h2'>
                           Perguntas frequentes
                        </Typography>
                     </Box>
                     <Accordion className={classes.accordion}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon className={classes.white} />} aria-controls="panel1a-content" id="panel1a-header">
                           <Typography className={classes.white}>
                              Porque devo estudar pelo DESAFIO PCSC?
                           </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                           <Typography className={classes.white}>
                              O DESAFIO PCSC é um programa de estudos com metas diárias desenvolvidas 
                              para alavancar seus estudos. São aulas teóricas e aulas com exercícios 
                              em que os professores respodem questões com embasamento teórico, além 
                              dos materiais complementares.
                           </Typography>
                        </AccordionDetails>
                     </Accordion>
                     <Accordion className={classes.accordion}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon className={classes.white} />} aria-controls="panel2a-content" id="panel2a-header">
                           <Typography className={classes.white}>
                              Quais as Formas de Pagamento?
                           </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                           <Typography className={classes.white}>
                              Pix, boleto bancário, cartão de crédito e financiamento estudantil.
                           </Typography>
                        </AccordionDetails>
                     </Accordion>
                  </Grid>
               </Grid>
            </Container>
         </Box>

         <Footer />

      </Grid>
   );
}