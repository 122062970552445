import React, { useRef } from 'react';
import {
   makeStyles, Grid, Container, Box, Typography, Button,
   List, ListItem, ListItemIcon, ListItemText
} from '@material-ui/core';
// import TopBar from '../../components/TopBar';
import Footer from '../../components/Footer';
import Professores from '../../components/Professores';
import Depoimentos from '../../components/Depoimentos';
// import Countdown from '../../components/Countdown';
import { Icon } from '@iconify/react';

// Images
import Bg from '../../images/vitalicio-bg.webp';
import Bg2 from '../../images/bg-3.webp';
import Bg3 from '../../images/bg-4.webp';
import Bg4 from '../../images/dease-bg-2.webp';
import Metodo from '../../images/ronaldo.webp';
import Mockup1 from '../../images/mockup-ead-1.png';
import Mockup2 from '../../images/mockup-ead-2.png';

const useStyles = makeStyles((theme) => ({
   root: {
      backgroundColor: theme.palette.secondary.dark,
      overflowX: 'hidden',
   },
   section: {
      position: 'relative',
      width: '100%',
      height: '100vh',
      maxHeight: '720px',
      backgroundImage: `url('${Bg}')`,
      backgroundSize: 'cover',
      backgroundPosition: 'left center',
      [theme.breakpoints.down('sm')]: {
         height: 'auto',
      },
   },
   containerHeader: {
      paddingTop: '8rem',
      position: 'relative',
      zIndex: '1',
      height: '100%',
      [theme.breakpoints.down('sm')]: {
         paddingTop: '8rem',
      }
   },
   relative: {
      position: 'relative',
   },
   buttonLabel: {
      textTransform: 'none !important',
   },
   iconBg: {
      color: theme.palette.secondary.main,
      fontFamily: 'Europa Regular',
   },
   white: {
      color: theme.palette.common.white,
   },
   display: {
      [theme.breakpoints.down('sm')]: {
         display: 'none',
      },
   },
   sup: {
      color: theme.palette.primary.main,
      textTransform: 'uppercase',
      fontWeight: '700',
   },
   primary: {
      color: theme.palette.primary.main,
   },
   container: {
      position: 'relative',
   },
   title: {
      textAlign: `center`,
   },
   bold: {
      color: theme.palette.secondary.main,
   },
   button: {
      color: theme.palette.primary.contrastText,
      boxShadow: 'none',
      borderRadius: '8px',
      padding: '0.5rem 2.5rem',
      fontSize: '1rem',
      fontWeight: '700',
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
         boxShadow: '0px 6px 30px rgba(240, 201, 0, 0.6) !important',
         backgroundColor: theme.palette.primary.main,
      },
   },
   hubspot: {
      maxHeight: '400px',
      overflow: 'hidden',
      [theme.breakpoints.down('sm')]: {
         maxHeight: '410px',
      },
   },
   iconHeader: {
      float: 'left',
      color: theme.palette.primary.main,
      fontSize: '1.75rem',
      marginTop: '0.1rem',
      marginRight: '1rem',
      marginBottom: '5rem',
      [theme.breakpoints.down('sm')]: {
         marginBottom: '7rem',
      }
   },
   icon: {
      float: 'left',
      color: theme.palette.primary.main,
      fontSize: '1.75rem',
      marginTop: '0',
      marginRight: '1rem',
      marginBottom: '1rem',
      [theme.breakpoints.down('sm')]: {
         marginBottom: '1rem',
      }
   },
   iconStart: {
      float: 'left',
      color: theme.palette.primary.main,
      fontSize: '1.75rem',
      marginTop: '-0.5rem',
      marginRight: '1rem',
      marginBottom: '1rem',
      [theme.breakpoints.down('sm')]: {
         marginBottom: '1rem',
      }
   },
   iconBig: {
      float: 'left',
      color: theme.palette.primary.main,
      fontSize: '2rem',
      marginTop: '0rem',
      marginRight: '1.75rem',
      marginBottom: '1rem',
      [theme.breakpoints.down('sm')]: {
         marginTop: '-0.25rem',
         marginRight: '1.25rem',
         marginBottom: '1.5rem',
      }
   },
   iconBigCenter: {
      color: theme.palette.primary.main,
      fontSize: '2rem',
      marginTop: '0rem',
      marginRight: '1.75rem',
      marginBottom: '1rem',
      [theme.breakpoints.down('sm')]: {
         marginTop: '-0.25rem',
         marginRight: '1.25rem',
         marginBottom: '1.5rem',
      }
   },
   iconCenter: {
      textAlign: 'center',
      color: theme.palette.primary.main,
      fontSize: '1.75rem',
      marginTop: '0rem',
      marginRight: '1rem',
      marginBottom: '1rem',
      [theme.breakpoints.down('sm')]: {
         marginBottom: '2rem',
      }
   },
   iconFire: {
      textAlign: 'center',
      color: theme.palette.primary.main,
      fontSize: '2rem',
      display: 'inline',
      padding: '0.2rem 0.75rem',
      margin: '0 0 0.75rem',
      [theme.breakpoints.down('sm')]: {
      }
   },
   iconClock: {
      textAlign: 'center',
      margin: '0 auto',
      color: theme.palette.common.white,
      fontSize: '1.75rem',
      display: 'inline',
      padding: '0.2rem 0.75rem',
      [theme.breakpoints.down('sm')]: {
      }
   },
   bg2: {
      width: 'auto',
      height: 'auto',
      // maxHeight: '600px',
      backgroundImage: `url('${Bg2}')`,
      backgroundSize: 'cover',
      backgroundPosition: 'left center',
      [theme.breakpoints.down('sm')]: {
         maxHeight: '100%',
      },
   },
   bg3: {
      height: '100%',
      backgroundImage: `url('${Bg3}')`,
      backgroundSize: 'cover',
      backgroundPosition: '60% center',
      [theme.breakpoints.down('sm')]: {
         maxHeight: '100%',
      },
   },
   bg4: {
      width: 'auto',
      height: 'auto',
      maxHeight: '600px',
      backgroundImage: `url('${Bg4}')`,
      backgroundSize: 'cover',
      backgroundPosition: 'left center',
      [theme.breakpoints.down('sm')]: {
         maxHeight: '100%',
      }
   },
   line: {
      height: '100%',
      width: '1px',
      borderWidth: '1px',
      borderStyle: 'dashed',
      color: theme.palette.primary.main,
   },
   number999: {
      fontSize: '1rem',
      fontWeight: '600',
      // textDecoration: 'line-through',
   },
   box: {
      // padding: '2rem',
      overflow: 'hidden',
      borderRadius: '8px',
      backgroundColor: '#13151E',
      boxShadow: '0 26px 30px -10px rgb(0 0 0 / 80%)',
      color: theme.palette.common.white,
      [theme.breakpoints.down('sm')]: {
         // padding: '0',
      }
   },
}));

export default function Home() {
   const classes = useStyles();

   const Inscricao = useRef(null);
   const LinkRef = () =>
      window.scrollTo({
         top: Inscricao.current.offsetTop,
         behavior: "smooth",
      });

   return (
      <Grid className={classes.root}>

         {/* <Countdown /> */}

         {/* Header */}
         <Grid className={classes.section}>
            <Container>
               <Grid
                  container
                  className={classes.containerHeader}
                  justifyContent='flex-start'
                  alignItems='center'
                  alignContent='center'
               >
                  <Grid item md='5' className={classes.relative}>
                     <Box mt={{ xs: 6, md: 4 }} pt={{ xs: 0, md: 8 }} pb={{ xs: 12, md: 0 }} pl={{ xs: 4, md: 0 }} pr={{ xs: 4, md: 14 }} textAlign='left'>
                        {/* <Box mb={4}>
                           <Typography component='sup' variant='body2' className={classes.sup}>
                              Abertura do Último Vitalício do RB Carreiras Policiais
                           </Typography>
                        </Box> */}
                        <Box mb={4}>
                           <Typography component='h1' variant='h1' className={classes.white}>
                              <div className={classes.primary}>Até a aprovação </div>
                              Maior desconto da história em nosso Vitalício total
                           </Typography>
                        </Box>
                        <Box mb={4}>
                           <Typography component='p' variant='body1' className={classes.white}>
                              Com bônus exclusivos!<br />
                              + Acesso vitalício<br />
                              + Mentoria Pertencerei (PRF) e Projeto Fox (PF)<br />
                              + Ninho das Águias<br />
                              + Inteligência Artificial de CTB<br />
                              + Encontro ao vivo com o Ronaldo Bandeira<br />
                           </Typography>
                        </Box>
                        <Box mb={8}>
                           <Typography component='p' variant='body1' className={classes.white}>
                              Nosso curso mais desejado do RB Carreiras Policiais com acesso vitalício a todo nosso site está de volta!!!
                           </Typography>
                        </Box>
                        <Box mb={4}>
                           <Button variant='contained' onClick={LinkRef}
                              classes={{
                                 root: classes.button,
                                 label: classes.buttonLabel,
                              }}>Quero aproveitar
                           </Button>
                        </Box>
                     </Box>
                  </Grid>
               </Grid>
            </Container>
         </Grid>

         {/* O que faz um policial civil? */}
         <Box pt={{ xs: 4, md: 24 }} pb={{ xs: 4, md: 0 }}>
            <Grid container justifyContent='center' spacing={8}>
               <Grid container justifyContent='center' alignItems='flex-start'>
                  <Grid item md={5} className={classes.white}>
                     <Box mb={{ xs: 8 }} textAlign='center'>
                        <Typography component='h2' variant='h2'>
                           O que você precisa para ser um policial?
                           {/* <span className={classes.primary}> </span> */}
                        </Typography>
                     </Box>
                  </Grid>
               </Grid>
               <Grid item md={6}>
                  <Grid container justifyContent='space-around' alignItems='flex-start' spacing={8}>
                     <Grid item md={6} className={classes.white}>
                        <Box mb={{ xs: 4, md: 4 }}>
                           <List>
                              <ListItem alignItems="flex-start">
                                 <ListItemIcon>
                                    <Icon icon="mdi:police-badge-outline" className={classes.icon} />
                                 </ListItemIcon>
                                 <ListItemText>
                                    <Typography variant='body1' component='span' className={classes.white}>
                                       Entre 18 e 65 anos
                                    </Typography>
                                 </ListItemText>
                              </ListItem>
                              <ListItem alignItems="flex-start">
                                 <ListItemIcon>
                                    <Icon icon="mdi:police-badge-outline" className={classes.icon} />
                                 </ListItemIcon>
                                 <ListItemText>
                                    <Typography variant='body1' component='span' className={classes.white}>
                                       Ensino Superior Completo
                                    </Typography>
                                 </ListItemText>
                              </ListItem>
                           </List>
                        </Box>
                     </Grid>
                     <Grid item md={6} className={classes.white}>
                        <Box mb={{ xs: 4, md: 4 }}>
                           <List>
                              <ListItem alignItems="flex-start">
                                 <ListItemIcon>
                                    <Icon icon="mdi:police-badge-outline" className={classes.icon} />
                                 </ListItemIcon>
                                 <ListItemText>
                                    <Typography variant='body1' component='span' className={classes.white}>
                                       Dedicação e preparação
                                    </Typography>
                                 </ListItemText>
                              </ListItem>
                              <ListItem alignItems="flex-start">
                                 <ListItemIcon>
                                    <Icon icon="mdi:police-badge-outline" className={classes.icon} />
                                 </ListItemIcon>
                                 <ListItemText>
                                    <Typography variant='body1' component='span' className={classes.white}>
                                       Ter o melhor e mais completo curso e mentoria à sua disposição!
                                    </Typography>
                                 </ListItemText>
                              </ListItem>
                           </List>
                        </Box>
                     </Grid>
                  </Grid>
               </Grid>
            </Grid>
         </Box>

         {/* O mais completo e atualizado preparatório do Brasil! 
         <Box py={{ xs: 4, md: 16 }}>
            <Grid container justifyContent='center' spacing={8}>
               <Grid container justifyContent='center' alignItems='flex-start'>
                  <Grid item md={5} className={classes.white}>
                     <Box mb={{ xs: 8 }} textAlign='center'>
                        <Typography component='h2' variant='h2'>
                           O mais completo e atualizado preparatório do Brasil!
                        </Typography>
                     </Box>
                     <Box mb={{ xs: 8, md: 12 }} textAlign='center'>
                        <Typography component='p' variant='h5'>
                           Com o Até a Gloriosa você contará com a melhor seleção de professores do Brasil e muito mais!
                        </Typography>
                     </Box>
                  </Grid>
               </Grid>
               <Grid item md={8}>
                  <Grid container justifyContent='space-around' alignItems='flex-start' spacing={8}>
                     <Grid item md={6} className={classes.white}>
                        <Box mb={{ xs: 4, md: 0 }}>
                           <List>
                              <ListItem alignItems="flex-start">
                                 <ListItemIcon>
                                    <Icon icon="ph:star-light" className={classes.iconStart} />
                                 </ListItemIcon>
                                 <ListItemText>
                                    <Typography variant='body2' component='span' className={classes.white}>
                                       Mais de 3.000 horas de vídeo aulas par seus estudos;
                                    </Typography>
                                 </ListItemText>
                              </ListItem>
                              <ListItem alignItems="flex-start">
                                 <ListItemIcon>
                                    <Icon icon="ph:star-light" className={classes.iconStart} />
                                 </ListItemIcon>
                                 <ListItemText>
                                    <Typography variant='body2' component='span' className={classes.white}>
                                       Centenas de PDFs para auxiliar sua preparação;
                                    </Typography>
                                 </ListItemText>
                              </ListItem>
                              <ListItem alignItems="flex-start">
                                 <ListItemIcon>
                                    <Icon icon="ph:star-light" className={classes.iconStart} />
                                 </ListItemIcon>
                                 <ListItemText>
                                    <Typography variant='body2' component='span' className={classes.white}>
                                       Exercícios e simulados;
                                    </Typography>
                                 </ListItemText>
                              </ListItem>
                              <ListItem alignItems="flex-start">
                                 <ListItemIcon>
                                    <Icon icon="ph:star-light" className={classes.iconStart} />
                                 </ListItemIcon>
                                 <ListItemText>
                                    <Typography variant='body2' component='span' className={classes.white}>
                                       Material 100% atualizado
                                    </Typography>
                                 </ListItemText>
                              </ListItem>
                           </List>
                        </Box>
                     </Grid>
                     <Grid item md={6} className={classes.white}>
                        <Box mb={{ xs: 4, md: 0 }}>
                           <List>
                              <ListItem alignItems="flex-start">
                                 <ListItemIcon>
                                    <Icon icon="ph:star-light" className={classes.iconStart} />
                                 </ListItemIcon>
                                 <ListItemText>
                                    <Typography variant='body2' component='span' className={classes.white}>
                                       Mentoria de Redação com Prof. Eleandra;
                                    </Typography>
                                 </ListItemText>
                              </ListItem>
                              <ListItem alignItems="flex-start">
                                 <ListItemIcon>
                                    <Icon icon="ph:star-light" className={classes.iconStart} />
                                 </ListItemIcon>
                                 <ListItemText>
                                    <Typography variant='body2' component='span' className={classes.white}>
                                       Ninho das Águias, a nossa mentoria de CTB;
                                    </Typography>
                                 </ListItemText>
                              </ListItem>
                              <ListItem alignItems="flex-start">
                                 <ListItemIcon>
                                    <Icon icon="ph:star-light" className={classes.iconStart} />
                                 </ListItemIcon>
                                 <ListItemText>
                                    <Typography variant='body2' component='span' className={classes.white}>
                                       Projeto pertencerei, um plano de estudos guiado para sua aprovação.
                                    </Typography>
                                 </ListItemText>
                              </ListItem>
                           </List>
                        </Box>
                     </Grid>
                     <Grid item md={12} className={classes.white}>
                        <Box mb={{ xs: 8, md: 12 }} textAlign='center'>
                           <Typography component='p' variant='h5'>
                              Nós desenvolvemos um plano a prova de desculpas para sua aprovação, agora é com você!
                           </Typography>
                        </Box>
                     </Grid>
                  </Grid>
               </Grid>
               <Grid item md={12}>
                  <Box textAlign='center'>
                     <Button variant='contained' onClick={LinkRef}
                        classes={{
                           root: classes.button,
                           label: classes.buttonLabel,
                        }}>Faça sua matrícula
                     </Button>
                  </Box>
               </Grid>
            </Grid>
         </Box> */}

         {/* Nosso método de ensino */}
         <Container>
            <Box mt={{ xs: 0, md: -24 }}>
               <Grid container justifyContent='center' alignItems='center' spacing={8}>
                  <Grid item md={7}>
                     <Box display={{ xs: 'none', md: 'block' }} ml={{ xs: 0, md: -24 }}>
                        <img src={Metodo} width='100%' alt='' />
                     </Box>
                  </Grid>
                  <Grid item md={4}>
                     <Box mt={{ xs: 0, md: 0 }} mb={{ xs: 8, md: 8 }} ml={{ xs: 0, md: -24 }} className={classes.white}>
                        <Typography component='h2' variant='h2'>
                           <Icon icon="fluent:trophy-28-regular" className={classes.icon} />
                           Nosso método de ensino
                        </Typography>
                     </Box>
                     <Box mb={{ xs: 8, md: 12 }} ml={{ xs: 0, md: -24 }}>
                        <Typography component='div' variant='h5' className={classes.white}>
                           Eu, Professor Ronaldo Bandeira, acredito no seu sonho e me dediquei a desenvolver uma metodologia de estudos única, que foi testada e validada com mais de 3.000 aprovados nos últimos concursos e agora disponível para prepara você!
                        </Typography>
                     </Box>
                     <Box mb={{ xs: 8, md: 4 }} ml={{ xs: 0, md: -24 }}>
                        <Typography component='div' variant='h4' className={classes.white}>
                           Nossas mentorias apresentam um plano de estudos diários para você pode estudar de forma guiada e estratégica para sua aprovação!
                        </Typography>
                     </Box>
                  </Grid>
               </Grid>
            </Box>
         </Container>

         {/* Maior mentoria */}
         <Box className={classes.bg2} pt={{ xs: 4, md: 24 }} pb={{ xs: 4, md: 12 }}>
            <Container maxWidth="xl">
               <Grid container justifyContent='center' spacing={8}>
                  <Grid container justifyContent='center' alignItems='flex-start'>
                     <Grid item md={5} className={classes.white}>
                        <Box mb={{ xs: 8 }} textAlign='center'>
                           <Typography component='h2' variant='h2'>
                              <span className={classes.primary}>EM NOSSAS MENTORIAS: </span>
                              PROJETO FOX (MENTORIA PARA PF) E PERTENCEREI (MENTORIA PARA PRF) VOCÊ ENCONTRARÁ:
                           </Typography>
                        </Box>
                     </Grid>
                  </Grid>
                  <Grid item md={12}>
                     <Grid container justifyContent='space-evenly' alignItems='flex-start' spacing={8}>
                        <Grid item md={4} className={classes.white}>
                           <Box display={{ xs: 'block', md: 'block' }} mt={{ xs: 0, md: -6 }} ml={{ xs: 0, md: 0 }} pt={{ xs: 0, md: 8 }}>
                              <img src={Mockup2} width='100%' alt='' />
                           </Box>
                        </Grid>
                        <Grid item md={3} className={classes.white}>
                           <Box mb={{ xs: 4, md: 4 }}>
                              <List>
                                 <ListItem alignItems="flex-start" className={classes.listItem}>
                                    <ListItemIcon>
                                       <Icon icon="mdi:police-badge-outline" className={classes.icon} />
                                    </ListItemIcon>
                                    <ListItemText>
                                       <Typography variant='body2' component='span' className={classes.white}>
                                          Acesso ao curso do Pertencerei na plataforma
                                       </Typography>
                                    </ListItemText>
                                 </ListItem>
                                 <ListItem alignItems="flex-start" className={classes.listItem}>
                                    <ListItemIcon>
                                       <Icon icon="mdi:police-badge-outline" className={classes.icon} />
                                    </ListItemIcon>
                                    <ListItemText>
                                       <Typography variant='body2' component='span' className={classes.white}>
                                          Ninho das Águias (Curso de Legislação de Trânsito)
                                       </Typography>
                                    </ListItemText>
                                 </ListItem>
                                 <ListItem alignItems="flex-start" className={classes.listItem}>
                                    <ListItemIcon>
                                       <Icon icon="mdi:police-badge-outline" className={classes.icon} />
                                    </ListItemIcon>
                                    <ListItemText>
                                       <Typography variant='body2' component='span' className={classes.white}>
                                          DESAFIO PERTENCEREI PRF com metas e missões diárias
                                       </Typography>
                                    </ListItemText>
                                 </ListItem>
                                 <ListItem alignItems="flex-start" className={classes.listItem}>
                                    <ListItemIcon>
                                       <Icon icon="mdi:police-badge-outline" className={classes.icon} />
                                    </ListItemIcon>
                                    <ListItemText>
                                       <Typography variant='body2' component='span' className={classes.white}>
                                          Vídeo-aulas de teoria e exercícios (Fixação e revisão do conteúdo)
                                       </Typography>
                                    </ListItemText>
                                 </ListItem>
                                 <ListItem alignItems="flex-start" className={classes.listItem}>
                                    <ListItemIcon>
                                       <Icon icon="mdi:police-badge-outline" className={classes.icon} />
                                    </ListItemIcon>
                                    <ListItemText>
                                       <Typography variant='body2' component='span' className={classes.white}>
                                          Simulados exclusivos com questões fundamentadas pelos professores
                                       </Typography>
                                    </ListItemText>
                                 </ListItem>
                              </List>
                           </Box>
                        </Grid>
                        <Grid item md={3} className={classes.white}>
                           <Box mb={{ xs: 0, md: 0 }}>
                              <List>
                                 <ListItem alignItems="flex-start" className={classes.listItem}>
                                    <ListItemIcon>
                                       <Icon icon="mdi:police-badge-outline" className={classes.icon} />
                                    </ListItemIcon>
                                    <ListItemText>
                                       <Typography variant='body2' component='span' className={classes.white}>
                                          Quiz com questões comentadas e gabaritadas para revisão de conteúdo
                                       </Typography>
                                    </ListItemText>
                                 </ListItem>
                                 <ListItem alignItems="flex-start" className={classes.listItem}>
                                    <ListItemIcon>
                                       <Icon icon="mdi:police-badge-outline" className={classes.icon} />
                                    </ListItemIcon>
                                    <ListItemText>
                                       <Typography variant='body2' component='span' className={classes.white}>
                                          PDFs, Mapas mentais e Flashcards
                                       </Typography>
                                    </ListItemText>
                                 </ListItem>
                                 <ListItem alignItems="flex-start" className={classes.listItem}>
                                    <ListItemIcon>
                                       <Icon icon="mdi:police-badge-outline" className={classes.icon} />
                                    </ListItemIcon>
                                    <ListItemText>
                                       <Typography variant='body2' component='span' className={classes.white}>
                                          RBCoins para troca posterior na RBSTORE
                                       </Typography>
                                    </ListItemText>
                                 </ListItem>
                                 <ListItem alignItems="flex-start" className={classes.listItem}>
                                    <ListItemIcon>
                                       <Icon icon="mdi:police-badge-outline" className={classes.icon} />
                                    </ListItemIcon>
                                    <ListItemText>
                                       <Typography variant='body2' component='span' className={classes.white}>
                                          Professores especialistas e com experiência no mercado
                                       </Typography>
                                    </ListItemText>
                                 </ListItem>
                                 <ListItem alignItems="flex-start" className={classes.listItem}>
                                    <ListItemIcon>
                                       <Icon icon="mdi:police-badge-outline" className={classes.icon} />
                                    </ListItemIcon>
                                    <ListItemText>
                                       <Typography variant='body2' component='span' className={classes.white}>
                                          Oficinas de Redação, Leg. de trânsito e exatas (matemática e física): Aulas, ao vivo, de reforço para sanar dúvidas de conteúdo (Em breve)
                                       </Typography>
                                    </ListItemText>
                                 </ListItem>
                              </List>
                           </Box>
                        </Grid>
                     </Grid>
                  </Grid>
               </Grid>
            </Container>
         </Box>

         {/* Nosso corpo docente... */}
         <Box px={8}>
            <Grid container justifyContent='center' spacing={8}>
               <Grid item md={12}>
                  <Box mt={{ xs: 12, md: 24 }} pb={{ xs: 24, md: 24 }} px={{ xs: 4, md: 0 }}>
                     <Grid container justifyContent='center' alignItems='flex-start'>
                        <Grid item xs={12} md={7}>
                           <Box textAlign='center' mb={{ xs: 4, md: 10 }}>
                              <Typography component='h3' variant='h1' className={classes.white}>
                                 O <span className={classes.primary}> mais qualificado acervo</span> de <br /> conteúdo para sua aprovação
                              </Typography>
                           </Box>
                           <Box textAlign='center'>
                              <Typography component='h4' variant='h4' className={classes.white}>
                                 Centenas de aulas gravadas e atualizadas com os mais renomados professores do país.
                              </Typography>
                           </Box>
                        </Grid>
                     </Grid>
                  </Box>
               </Grid>
            </Grid>
            <Professores />
         </Box>

         {/* Estudar muito é diferente... */}
         <Box pt={{ xs: 16, md: 24 }} pb={{ xs: 16, md: 24 }} px={{ xs: 4, md: 0 }}>
            <Container className={classes.container}>
               <Grid container justifyContent='center' alignItems='flex-start' className={classes.white}>
                  <Grid item md={12}>
                     <Box mb={{ xs: 8 }} textAlign='center'>
                        <Icon icon="uil:fire" className={classes.iconFire} />
                        <Typography component='h2' variant='h1'>
                           Estudar muito é diferente de
                           <div className={classes.primary}>estudar de forma inteligente.</div>
                        </Typography>
                     </Box>
                     <Grid container justifyContent='center'>
                        <Grid item md={6}>
                           <Box mb={{ xs: 8 }} textAlign='center'>
                              <Typography component='h4' variant='h4'>
                                 Chegou a hora de você investir em você e ter acesso à tudo isso e as futuras novidades do RB com o nosso Vitalício.
                              </Typography>
                           </Box>
                           <Box textAlign='center' mb={{ xs: 24, md: 24 }}>
                              <Typography component='h4' variant='h4' className={classes.white}>
                                 Veja o que nossos alunos falam sobre nossos cursos:
                              </Typography>
                           </Box>
                        </Grid>
                     </Grid>
                  </Grid>
               </Grid>

               {/* <Box mt={{ xs: 12, md: 24 }} mb={{ xs: 0, md: 24 }}> */}
               {/* <Grid container justifyContent='center' alignItems='center' className={classes.white}> */}
               {/* <Grid item md={3}> */}
               {/* <Box mb={{ xs: 8 }} textAlign='center'> */}
               {/* <Icon icon="uil:clock" className={classes.iconClock} /> */}
               {/* <Typography component='h2' variant='h1'> */}
               {/* <Box className={classes.primary}>25/01</Box> */}
               {/* </Typography> */}
               {/* <Box mb={{ xs: 8 }} textAlign='center'> */}
               {/* <Typography component='h5' variant='h5'> */}
               {/* Conheça a Fórmula para a sua aprovação! Pré venda do Vitalício e lançamento do Projeto Fox */}
               {/* </Typography> */}
               {/* </Box> */}
               {/* </Box> */}
               {/* </Grid> */}
               {/* <Grid item md={1}> */}
               {/* <Box textAlign='center' display={{ xs: 'none', md: 'block' }}> */}
               {/* <Icon icon="ep:arrow-right" className={classes.iconClock} /> */}
               {/* </Box> */}
               {/* </Grid> */}
               {/* <Grid item md={3}> */}
               {/* <Box mb={{ xs: 8 }} textAlign='center'> */}
               {/* <Icon icon="uil:clock" className={classes.iconClock} /> */}
               {/* <Typography component='h2' variant='h1'> */}
               {/* <Box className={classes.primary}>01/02</Box> */}
               {/* </Typography> */}
               {/* <Box mb={{ xs: 8 }} textAlign='center'> */}
               {/* <Typography component='h5' variant='h5'> */}
               {/* A Data do Próximo Concurso PRF e PF, sem enrolação + Abertura do último Vitalício do ano. */}
               {/* </Typography> */}
               {/* </Box> */}
               {/* </Box> */}
               {/* </Grid> */}
               {/* </Grid> */}
               {/* </Box> */}

            </Container>
            <Depoimentos />
         </Box>

         {/* Nós lidamos com sonhos e mudança de vida! */}
         <Grid container justifyContent='center'>
            <Grid item xs={12} md={11}>

               <Box mt={{ xs: 0, md: 0 }} py={{ xs: 20, md: 20 }} px={{ xs: 4, md: 0 }}>
                  <Container className={classes.container}>
                     <Grid container justifyContent='center' alignItems='flex-start'>
                        <Grid item md={12} className={classes.white}>
                           <Box mb={8} px={{ xs: 8, md: 0 }} textAlign='center'>
                              <Typography component='h2' variant='h1'>
                                 Nós lidamos com sonhos e mudança de vida!
                              </Typography>
                           </Box>
                        </Grid>
                        <Grid item md={6} className={classes.white}>
                           <Box mt={{ xs: 8 }} mb={{ xs: 8 }} textAlign='center'>
                              <Typography component='p' variant='h5'>
                                 Se você quer fazer parte de uma das instituições policiais mais reconhecidas do Brasil, ter o reconhecimento da sua família, desfrutar de uma vida financeira tranquila e a estabilidade de um concursado federal, chegou a hora de você fazer a sua escolha rumo à gloriosa!
                              </Typography>
                           </Box>
                        </Grid>
                     </Grid>
                  </Container>

                  <Box mt={{ xs: 16 }}>
                     <Grid container justifyContent='space-between' spacing={8}>
                        <Grid item md={6}>
                           <Grid item xs={12} md={10}>
                              <Box className={classes.white} px={{ xs: 4, md: 6 }}>
                                 <Typography component='p' variant='h5'>
                                    Aproveite o Até a Gloriosa com condições especiais de Aniversário e garanta de forma vitalícia:
                                 </Typography>
                              </Box>
                           </Grid>
                           <Box py={{ xs: 4, md: 8 }} px={{ xs: 4, md: 0 }}>
                              <Grid container justifyContent='left'>
                                 <Grid item xs={12} md={12}>
                                    <Grid container justifyContent='space-between' alignItems='flex-start' spacing={8}>
                                       <Grid item md={6} className={classes.white}>
                                          <Box mb={{ xs: 0, md: 4 }}>
                                             <List>
                                                <ListItem alignItems="flex-start">
                                                   <ListItemIcon>
                                                      <Icon icon="fluent:flash-28-regular" className={classes.iconStart} />
                                                   </ListItemIcon>
                                                   <ListItemText>
                                                      <Typography variant='body2' component='span' className={classes.white}>
                                                         Mais de 3.000 horas de aulas gravadas;
                                                      </Typography>
                                                   </ListItemText>
                                                </ListItem>
                                                <ListItem alignItems="flex-start">
                                                   <ListItemIcon>
                                                      <Icon icon="fluent:flash-28-regular" className={classes.iconStart} />
                                                   </ListItemIcon>
                                                   <ListItemText>
                                                      <Typography variant='body2' component='span' className={classes.white}>
                                                         Aulas com os melhores professores do Brasil;
                                                      </Typography>
                                                   </ListItemText>
                                                </ListItem>
                                                <ListItem alignItems="flex-start">
                                                   <ListItemIcon>
                                                      <Icon icon="fluent:flash-28-regular" className={classes.iconStart} />
                                                   </ListItemIcon>
                                                   <ListItemText>
                                                      <Typography variant='body2' component='span' className={classes.white}>
                                                         Conteúdo 100% atualizado;
                                                      </Typography>
                                                   </ListItemText>
                                                </ListItem>
                                                <ListItem alignItems="flex-start">
                                                   <ListItemIcon>
                                                      <Icon icon="fluent:flash-28-regular" className={classes.iconStart} />
                                                   </ListItemIcon>
                                                   <ListItemText>
                                                      <Typography variant='body2' component='span' className={classes.white}>
                                                         Uma plataforma rápida e leve;
                                                      </Typography>
                                                   </ListItemText>
                                                </ListItem>
                                                <ListItem alignItems="flex-start">
                                                   <ListItemIcon>
                                                      <Icon icon="fluent:flash-28-regular" className={classes.iconStart} />
                                                   </ListItemIcon>
                                                   <ListItemText>
                                                      <Typography variant='body2' component='span' className={classes.white}>
                                                         Materiais complementares de estudo;
                                                      </Typography>
                                                   </ListItemText>
                                                </ListItem>
                                             </List>
                                          </Box>
                                       </Grid>
                                       <Grid item md={6} className={classes.white}>
                                          <Box mb={{ xs: 0, md: 4 }} mt={{ xs: -6, md: 0 }}>
                                             <List>
                                                <ListItem alignItems="flex-start">
                                                   <ListItemIcon>
                                                      <Icon icon="fluent:flash-28-regular" className={classes.iconStart} />
                                                   </ListItemIcon>
                                                   <ListItemText>
                                                      <Typography variant='body2' component='span' className={classes.white}>
                                                         Exercícios e Simulados;
                                                      </Typography>
                                                   </ListItemText>
                                                </ListItem>
                                                <ListItem alignItems="flex-start">
                                                   <ListItemIcon>
                                                      <Icon icon="fluent:flash-28-regular" className={classes.iconStart} />
                                                   </ListItemIcon>
                                                   <ListItemText>
                                                      <Typography variant='body2' component='span' className={classes.white}>
                                                         Lives tira dúvida;
                                                      </Typography>
                                                   </ListItemText>
                                                </ListItem>
                                                <ListItem alignItems="flex-start">
                                                   <ListItemIcon>
                                                      <Icon icon="fluent:flash-28-regular" className={classes.iconStart} />
                                                   </ListItemIcon>
                                                   <ListItemText>
                                                      <Typography variant='body2' component='span' className={classes.white}>
                                                         Acesso ao Ninho das Águias;
                                                      </Typography>
                                                   </ListItemText>
                                                </ListItem>
                                                <ListItem alignItems="flex-start">
                                                   <ListItemIcon>
                                                      <Icon icon="fluent:flash-28-regular" className={classes.iconStart} />
                                                   </ListItemIcon>
                                                   <ListItemText>
                                                      <Typography variant='body2' component='span' className={classes.white}>
                                                         Mentoria de Redação;
                                                      </Typography>
                                                   </ListItemText>
                                                </ListItem>
                                                <ListItem alignItems="flex-start">
                                                   <ListItemIcon>
                                                      <Icon icon="fluent:flash-28-regular" className={classes.iconStart} />
                                                   </ListItemIcon>
                                                   <ListItemText>
                                                      <Typography variant='body2' component='span' className={classes.white}>
                                                         Acesso ao Projeto Pertencerei;
                                                      </Typography>
                                                   </ListItemText>
                                                </ListItem>
                                             </List>
                                          </Box>
                                       </Grid>
                                    </Grid>
                                 </Grid>
                              </Grid>
                           </Box>
                        </Grid>
                        <Grid item md={6}>
                           <Box display={{ xs: 'block', md: 'block' }} mt={{ xs: 0, md: -6 }} ml={{ xs: 0, md: 0 }} pt={{ xs: 0, md: 8 }}>
                              <img src={Mockup1} width='100%' alt='' />
                           </Box>
                        </Grid>
                     </Grid>
                  </Box>

               </Box>
            </Grid>
         </Grid>

         {/* Inscrição  */}
         <Box ref={Inscricao} className={classes.bg4} pt={{ xs: 18, md: 30 }} mb={{ xs: 4, md: 20 }} px={{ xs: 4, md: 0 }} pb={{ xs: 4, md: 10 }}>
            <Container>
               <Grid container className={classes.white} justifyContent='space-around' alignItems='center' direction='column'>
                  <Grid item md='7'>
                     <Box mb={{ xs: 12 }} ml={{ md: 0 }} textAlign='center'>
                        <Typography component='h3' variant='h5'>
                           Se não bastasse tudo isso, nós ainda damos uma garantia de 7 dias para você navegar em todo nosso conteúdo e, se não gostar, damos todo seu dinheiro de volta!
                        </Typography>
                     </Box>
                  </Grid>
                  <Grid item md='8'>
                     <Grid container justifyContent='center' spacing={10}>

                        <Grid item md={9}>
                           <Box className={classes.box} p={12}>
                              <Box mb={4} textAlign='center'>
                                 <Typography component='h4' variant='h2'>
                                    Pague com cartão ou Pix
                                 </Typography>
                              </Box>
                              <Box mb={8} textAlign='center'>
                                 <Typography component='p' variant='body1'>
                                    Pague com toda segurança utilizando a Plataforma da Eduzz.
                                 </Typography>
                              </Box>
                              <Box mb={1} textAlign='center'>
                                 <Typography component='p' variant='body1'>
                                    De <Typography component='span' variant='h5'>R$ 2499,00</Typography>
                                 </Typography>
                              </Box>
                              {/* <Box mb={1} textAlign='center'> */}
                              {/* <Typography component='p' variant='body1'> */}
                              {/* À vista por <Typography component='span' variant='h3'>R$ 849,00</Typography> */}
                              {/* </Typography> */}
                              {/* </Box> */}
                              <Box mb={1} textAlign='center'>
                                 <Grid container justifyContent='center' alignItems='center' alignContent='center' spacing={0}>
                                    <Grid item>
                                       <Icon icon="uil:fire" className={classes.iconFire} />
                                    </Grid>
                                    <Grid item>
                                       <Typography component='span' variant='h2'>
                                          por 12x de
                                          <span className={classes.primary}> R$ 70,75</span>
                                       </Typography>
                                    </Grid>
                                    <Grid item>
                                       <Icon icon="uil:fire" className={classes.iconFire} />
                                    </Grid>
                                 </Grid>
                              </Box>
                              <Box mb={8} textAlign='center'>
                                 <Typography component='p' variant='body2'>
                                    Limitado a 30 vagas!
                                 </Typography>
                              </Box>
                              <Box textAlign='center'>
                                 <Button variant='contained' href='https://sun.eduzz.com/z3dxvrzl'
                                    classes={{
                                       root: classes.button,
                                       label: classes.buttonLabel,
                                    }}>Pagar agora
                                 </Button>
                              </Box>
                           </Box>
                        </Grid>

                     </Grid>
                  </Grid>
                  <Grid item md='5'>
                     <Box mt={{ xs: 16 }} mb={{ xs: 8 }} px={{ xs: 0 }} className={classes.white} textAlign='center'>
                        <Typography component='p' variant='h4'>
                           Faça parte do Até a Gloriosa e temos um encontro marcado na sua formatura!
                        </Typography>
                     </Box>
                  </Grid>
               </Grid>
            </Container>
         </Box>


         <Box position='relative' zIndex='1'>
            <Footer />
         </Box>

      </Grid >
   );
}