import React, { useRef } from 'react';
import {
   makeStyles, Grid, Container, Box, Typography, Button,
   // List, ListItem, ListItemIcon, ListItemText
} from '@material-ui/core';

// Components
import TopBar from '../../components/TopBar';
import Footer from '../../components/Footer';
import { Icon } from '@iconify/react';
import './style.css';

// Images
import Bg from '../../images/ninho-bg.webp';
import Bg2 from '../../images/ninho-bg-2.webp';
import Bg3 from '../../images/dease-bg-2.webp';
import Mockup2 from '../../images/mockup-ead-2.png';
import Ronaldo from '../../images/ronaldo.webp';

const useStyles = makeStyles((theme) => ({
   // Globals
   root: {
      backgroundColor: theme.palette.secondary.dark,
      overflowX: 'hidden',
   },
   section: {
      position: 'relative',
      width: '100%',
      height: '100%',
      // maxHeight: '720px',
      backgroundImage: `url('${Bg}')`,
      backgroundSize: 'cover',
      backgroundPosition: 'left center',
      [theme.breakpoints.down('sm')]: {
         height: 'auto',
      },
   },
   header: {
      width: '100%',
      height: '100vh',
      maxHeight: '650px',
      // [theme.breakpoints.down('sm')]: {
      //    paddingTop: '8rem',
      // }
   },
   // sup: {
   // color: theme.palette.primary.main,
   // textTransform: 'uppercase',
   // fontWeight: '700',
   // },
   primary: {
      color: theme.palette.primary.main,
   },
   secondary: {
      color: theme.palette.secondary.main,
   },
   white: {
      color: theme.palette.common.white,
   },
   button: {
      color: theme.palette.primary.contrastText,
      boxShadow: 'none',
      borderRadius: '8px',
      padding: '0.5rem 2.5rem',
      fontSize: '1rem',
      fontWeight: '700',
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
         boxShadow: '0px 6px 30px rgba(240, 201, 0, 0.6) !important',
         backgroundColor: theme.palette.primary.main,
      },
   },
   buttonLabel: {
      textTransform: 'none !important',
   },
   relative: {
      position: 'relative',
   },

   // Seja bem vindo
   bg2: {
      width: 'auto',
      height: 'auto',
      backgroundImage: `url('${Bg2}')`,
      backgroundSize: 'cover',
      backgroundPosition: 'left center',
      [theme.breakpoints.down('sm')]: {
         maxHeight: '100%',
      }
   },
   bg3: {
      width: 'auto',
      height: 'auto',
      maxHeight: '600px',
      backgroundImage: `url('${Bg3}')`,
      backgroundSize: 'cover',
      backgroundPosition: 'left center',
      [theme.breakpoints.down('sm')]: {
         maxHeight: '100%',
      }
   },
   iconArrowDown: {
      textAlign: 'center',
      color: theme.palette.primary.main,
      fontSize: '1.5rem',
      marginTop: '0rem',
      marginRight: '1rem',
      marginBottom: '1rem',
      [theme.breakpoints.down('sm')]: {
         marginBottom: '2rem',
      }
   },

   // O que faz um policial civil?
   icon: {
      float: 'left',
      color: theme.palette.primary.main,
      fontSize: '1.75rem',
      marginRight: '1rem',
   },

   // FAQ
   accordion: {
      padding: '0.5rem',
      backgroundColor: `#13151E !important`,
      borderRadius: '8px !important',
      marginBottom: '1.5rem',
      border: 'none !important',
      boxShadow: 'none !important',
      '&::before': {
         backgroundColor: 'transparent !important',
      }
   },
   box: {
      padding: '2rem',
      overflow: 'hidden',
      borderRadius: '8px',
      backgroundColor: '#13151E !important',
      boxShadow: '0 26px 30px -10px rgb(0 0 0 / 80%)',
      [theme.breakpoints.down('sm')]: {
         // padding: '0',
      }
   },
   iconFire: {
      color: theme.palette.primary.main,
      fontSize: '1.5rem',
      display: 'inline',
      lineHeight: '2rem',
      margin: '0 5px',
      [theme.breakpoints.down('sm')]: {
      }
   },

}));

export default function NinhoDasAguias() {
   const classes = useStyles();

   const Inscricao = useRef(null);
   const LinkRef = () =>
      window.scrollTo({
         top: Inscricao.current.offsetTop,
         behavior: "smooth",
      });

   return (
      <Grid className={classes.root}>

         <TopBar />

         {/* Header */}
         <Grid className={classes.section}>
            <Container>
               <Grid className={classes.header} container justifyContent='flex-start' alignItems='center'>
                  <Grid item md='5'>
                     <Box mt={{ xs: 6, md: 0 }} pt={{ xs: 0, md: 8 }} pb={{ xs: 12, md: 0 }} pl={{ xs: 4, md: 0 }} pr={{ xs: 4, md: 14 }} textAlign='left'>
                        <Box mb={4}>
                           <Grid item md='8'>
                              <Typography component='sup' variant='body2' class='sup'>
                                 <Icon icon="game-icons:eagle-head" className={classes.icon} />
                                 O Maior e Melhor Curso de CTB do Brasil está de volta!
                              </Typography>
                           </Grid>
                        </Box>
                        <Box mb={4}>
                           <Typography component='h1' variant='h1' className={classes.white}>
                              <div className={classes.primary}>Ninho das Àguias 2.0</div>
                              {/* Até a sua Aprovação */}
                           </Typography>
                        </Box>
                        <Box mb={4}>
                           <Typography component='p' variant='body1' className={classes.white}>
                              Uma mentoria semanal, ao vivo, com o Melhor Professor de CTB do Brasil,
                              tirando todas as suas dúvidas e te passando o mais completo e atualizado
                              conteúdo.
                           </Typography>
                        </Box>
                        <Box mb={8}>
                           <Typography component='p' variant='h4' className={classes.white}>
                              {/* <span className={classes.secondary}>De R$ 397</span> -  */}
                              {/* <span className={classes.primary}>Por R$ 317,60</span> */}
                              {/* Por R$ 238,00 */}
                           </Typography>
                        </Box>
                        <Box mb={4}>
                           <Button variant='contained' onClick={LinkRef}
                              classes={{
                                 root: classes.button,
                                 label: classes.buttonLabel,
                              }}>Faça parte do Ninho
                           </Button>
                        </Box>
                     </Box>
                  </Grid>
                  <Grid item md='5'></Grid>
               </Grid>
            </Container >
         </Grid >

         {/* SOBRE O CURSO */}
         < Box pt={{ xs: 10, md: 20 }
         } px={{ xs: 4, md: 0 }} pb={{ xs: 10, md: 20 }}>
            <Container className={classes.container}>
               <Grid container justifyContent='center' alignItems='flex-start'>
                  <Grid item md={7} className={classes.white}>
                     <Box mb={{ xs: 8 }} textAlign='center'>
                        <Typography component='h2' variant='h2'>
                           Chegou a sua hora de voar alto.
                        </Typography>
                     </Box>
                     <Box mb={{ xs: 8, md: 24 }} textAlign='center'>
                        <Typography component='em' variant='body1'>
                           “Mas os que esperam no senhor renovarão as suas forças, subirão com asas
                           como águias, correrão e não se cansarão, caminharão e não se fatigarão.
                           Isaías 40:31”
                        </Typography>
                     </Box>
                     {/* <Box mb={{ xs: 8, md: 8 }} textAlign='center'> */}
                     {/* <Typography component='p' variant='body1'> */}
                     {/* Águias voam com Águias e isso define bem o Ninho das Águias. */}
                     {/* Uma Mentoria Aproximada com o Melhor Professor de CTB do Brasil, */}
                     {/* Ronaldo Bandeira. */}
                     {/* </Typography> */}
                     {/* </Box> */}
                     {/* <Box mb={{ xs: 8, md: 8 }} textAlign='center'> */}
                     {/* <Typography component='p' variant='body1'> */}
                     {/* Além do acesso a aulas e palestras exclusivas, o aluno ainda */}
                     {/* tem a possibilidade de sanar dúvidas diretamente com o Professor */}
                     {/* Bandeira. Os últimos Concursos da Polícia Rodoviária Federal são */}
                     {/* prova disso – mais de 1.700 aprovados e você vai */}
                     {/* entrar para essa contagem. */}
                     {/* </Typography> */}
                     {/* </Box> */}
                     {/* <Box textAlign='center'> */}
                     {/* <Icon icon="material-symbols:arrow-circle-down-outline-rounded" className={classes.iconArrowDown} /> */}
                     {/* </Box> */}
                  </Grid>
                  <Grid item md={12} className={classes.white}>
                     <Grid className={classes.white} container justifyContent='space-around' alignItems='center' direction='column'>
                        <Grid item md='5'>
                           <Box mb={{ xs: 8, md: 16 }} ml={{ md: 0 }} textAlign='center'>
                              <Typography component='h2' variant='h2'>
                                 Tenha acesso ao mais completo e atualizado conteúdo de CTB do Brasil.
                              </Typography>
                           </Box>
                        </Grid>
                        <Grid item md='12'>
                           <Grid container alignContent='center' justifyContent='center'>
                              <Grid item xs={12} md={4}>
                                 <Box className={classes.listItem} p={3} textAlign='left'>
                                    <Icon icon="fluent:flash-28-regular" className={classes.icon} />
                                    <Typography variant='body1' component='span' className={classes.listItemWhite}>
                                       Aulas exclusivas
                                    </Typography>
                                 </Box>
                                 <Box className={classes.listItem} p={3} textAlign='left'>
                                    <Icon icon="fluent:flash-28-regular" className={classes.icon} />
                                    <Typography variant='body1' component='span' className={classes.listItemWhite}>
                                       Palestras
                                    </Typography>
                                 </Box>
                                 <Box className={classes.listItem} p={3} textAlign='left'>
                                    <Icon icon="fluent:flash-28-regular" className={classes.icon} />
                                    <Typography variant='body1' component='span' className={classes.listItemWhite}>
                                       Material complementar
                                    </Typography>
                                 </Box>
                              </Grid>
                              <Grid item xs={12} md={4}>
                                 <Box className={classes.listItem} p={3} textAlign='left'>
                                    <Icon icon="fluent:flash-28-regular" className={classes.icon} />
                                    <Typography variant='body1' component='span' className={classes.listItemWhite}>
                                       Exercícios
                                    </Typography>
                                 </Box>
                                 <Box className={classes.listItem} p={3} textAlign='left'>
                                    <Icon icon="fluent:flash-28-regular" className={classes.icon} />
                                    <Typography variant='body1' component='span' className={classes.listItemWhite}>
                                       Suporte pedagógico
                                    </Typography>
                                 </Box>
                                 <Box className={classes.listItem} p={3} textAlign='left'>
                                    <Icon icon="fluent:flash-28-regular" className={classes.icon} />
                                    <Typography variant='body1' component='span' className={classes.listItemWhite}>
                                       Grupo de Alunos
                                    </Typography>
                                 </Box>
                              </Grid>
                              <Grid item xs={12} md={4}>
                                 <Box className={classes.listItem} p={3} textAlign='left'>
                                    <Icon icon="fluent:flash-28-regular" className={classes.icon} />
                                    <Typography variant='body1' component='span' className={classes.listItemWhite}>
                                       Lives Semanais com o Bandeirinha, o melhor professor de CTB do Brasil
                                    </Typography>
                                 </Box>
                                 <Box className={classes.listItem} p={3} textAlign='left'>
                                    <Icon icon="fluent:flash-28-regular" className={classes.icon} />
                                    <Typography variant='body1' component='span' className={classes.listItemWhite}>
                                       Os conteúdos mais atualizados
                                    </Typography>
                                 </Box>
                              </Grid>
                           </Grid>
                        </Grid>
                        <Grid item md={6} className={classes.white}>
                           <Box mt={{ xs: 8, md: 24 }} mb={{ xs: 4 }} textAlign='center'>
                              <Typography component='h4' variant='h4'>
                                 Você está disposto a pagar o preço para viver o seu sonho?
                              </Typography>
                           </Box>
                           <Box mb={{ xs: 8, md: 12 }} textAlign='center'>
                              <Typography component='p' variant='body1'>
                                 Então venha para o Ninho, prepare-se com o melhor material do Brasil e esteja pronto para sua aprovação.
                              </Typography>
                           </Box>
                        </Grid>
                     </Grid>
                  </Grid>
               </Grid>
            </Container>
         </Box >

         <Box className={classes.bg2} pt={{ xs: 10, md: 10 }} px={{ xs: 4, md: 0 }} pb={{ xs: 10, md: 10 }}>

            {/* Público alvo */}
            <Box px={{ xs: 4, md: 0 }} py={{ xs: 4, md: 8 }}>
               <Grid container className={classes.white} justifyContent='center' alignItems='center'>
                  <Grid item md={8} className={classes.white}>
                     <Grid container className={classes.white} justifyContent='space-evenly' alignItems='center' spacing={8}>
                        <Grid item md={6} className={classes.white}>
                           <Box display={{ xs: 'block', md: 'block' }} mb={{ xs: 8, md: 0 }} ml={{ xs: 0, md: 0 }} pt={{ xs: 0, md: 0 }}>
                              <img src={Mockup2} width='100%' alt='' />
                           </Box>
                        </Grid>
                        <Grid item md={4} className={classes.white}>
                           <Box mb={{ xs: 8 }} textAlign='left'>
                              <Typography component='h2' variant='h2'>
                                 <span className={classes.primary}>Por que </span>
                                 fazer parte do Ninho?
                              </Typography>
                           </Box>
                           <Box mb={{ xs: 8, md: 12 }} textAlign='left'>
                              <Typography component='p' variant='body1'>
                                 O Ninho das Águias é o maior, mais completo e mais atualizado curso de
                                 Código de Trânsito Brasileiro - CTB, conhecimento cada vez mais exigido em
                                 diversos concursos da área da segurança pública.
                              </Typography>
                           </Box>
                           <Box mb={{ xs: 8, md: 0 }} textAlign='left'>
                              <Typography component='p' variant='body1'>
                                 O CTB deve ser encarado na letra da lei e também interpretado nas mais
                                 diversas situações que se faça necessário entendê-lo e empregá-lo. Com o
                                 Ninho das Águias você irá se preparar com o mais completo conteúdo
                                 empregado em situações simuladas para desenvolver seu entendimento.
                              </Typography>
                           </Box>
                        </Grid>
                     </Grid>
                  </Grid>
               </Grid>
            </Box >

            {/* Para quem é o Ninho? */}
            <Box px={{ xs: 4, md: 0 }} py={{ xs: 4, md: 8 }} mt={{ xs: 0, md: 8 }}>
               <Grid container justifyContent='center' spacing={8}>
                  <Grid item md={4} className={classes.white}>
                     <Box mb={{ xs: 8 }} textAlign='left'>
                        <Typography component='h2' variant='h2'>
                           <span className={classes.primary}>Para quem </span> é o Ninho?
                        </Typography>
                     </Box>
                     <Box mb={{ xs: 8, md: 8 }} textAlign='left'>
                        <Typography component='p' variant='body1'>
                           Concurseiros das Carreiras Policiais, como Policias Militares e Policia
                           Rodoviária Federal, onde já aprovamos mais de 1.700 alunos nos últimos
                           concursos.
                        </Typography>
                        <Box mt={{ xs: 8, md: 8 }}>
                           <Typography component='p' variant='body1'>
                              Com uma metodologia única de ensino, o Professor Ronaldo Bandeira irá te
                              pegar pela mão e auxiliará você de forma direta para a sua aprovação,
                              adaptando o conteúdo à banca do seu concurso e a forma que ela cobra o
                              conhecimento do CTB.
                           </Typography>
                        </Box>
                     </Box>
                  </Grid>
               </Grid>
            </Box>

            {/* Benefícios */}
            {/* <Box px={{ xs: 4, md: 0 }} py={{ xs: 4, md: 16 }}> */}
            {/* <Grid container justifyContent='center' spacing={8}> */}
            {/* <Grid container justifyContent='center' alignItems='flex-start'> */}
            {/* <Grid item md={5} className={classes.white}> */}
            {/* <Box mb={{ xs: 8 }} textAlign='center'> */}
            {/* <Typography component='h2' variant='h2'> */}
            {/* <span className={classes.white}>Garanta a sua vaga agora mesmo </span> */}
            {/* </Typography> */}
            {/* </Box> */}
            {/* <Box mb={4} textAlign='center'> */}
            {/* <Button variant='contained' href='https://sun.eduzz.com/2138637'> */}
            {/* </Button> */}
            {/* </Box> */}
            {/* </Grid> */}
            {/* </Grid> */}
            {/* </Grid> */}
            {/* </Box> */}

         </Box>

         {/* Águia voa com Águia! */}
         <Box px={{ xs: 4, md: 0 }} py={{ xs: 4, md: 8 }}>
            <Grid container className={classes.white} justifyContent='center' alignItems='center'>
               <Grid item md={8} className={classes.white}>
                  <Grid container className={classes.white} justifyContent='space-evenly' alignItems='center' spacing={4}>
                     <Grid item md={4} className={classes.white}>
                        <Box mb={{ xs: 8 }} textAlign='left'>
                           <Typography component='h2' variant='h2'>
                              Águia voa com Águia!
                           </Typography>
                        </Box>
                        <Box mb={{ xs: 4, md: 8 }} textAlign='left'>
                           <Typography component='p' variant='body1'>
                              Só com o Ninho você poderá estudar com o, reconhecidamente, Melhor
                              Professor de CTB do Brasil! Após alcançar êxito preparando e aprovando
                              milhares de alunos para os últimos concursos da PRF e acertando grande parte
                              dos exercícios que foram cobrados em prova, não tem porque você estudar em
                              outro lugar.
                           </Typography>
                        </Box>
                        <Box mb={{ xs: 8, md: 8 }} textAlign='left'>
                           <Typography component='p' variant='body1'>
                              Além disso você terá lives exclusivas toda semana direto com o
                              Professor Ronaldo Bandeira e acesso estendido até a sua aprovação!
                           </Typography>
                        </Box>
                        <Box mb={{ xs: 8, md: 0 }} textAlign='left'>
                           <Typography component='p' variant='h4' className={classes.primary}>
                              Se seu objetivo é voar alto, você não tem mais desculpas. Venha para o ninho.
                           </Typography>
                        </Box>
                     </Grid>
                     <Grid item md={6} className={classes.white}>
                        <Box display={{ xs: 'block', md: 'block' }} mb={{ xs: 8, md: 0 }} ml={{ xs: 0, md: 0 }} pt={{ xs: 0, md: 8 }}>
                           <img src={Ronaldo} width='100%' alt='' />
                        </Box>
                     </Grid>
                  </Grid>
               </Grid>
            </Grid>
         </Box>

         {/* Planos - antigo  */}
         <Box ref={Inscricao} className={classes.bg3} pt={{ xs: 18, md: 30 }} mb={{ xs: 4, md: 20 }} px={{ xs: 4, md: 0 }} pb={{ xs: 4, md: 10 }}>
            <Container>
               <Grid container className={classes.white} justifyContent='space-around' alignItems='center' direction='column'>
                  <Grid item md='4'>
                     <Box mb={{ xs: 2 }} textAlign='center'>
                        <Typography component='h3' variant='h2'>
                           7 dias de garantia
                        </Typography>
                     </Box>
                     <Box mb={{ xs: 12 }} textAlign='center'>
                        <Typography component='p' variant='body1'>
                           Se você não gostar do nosso curso entregamos 100% do seu dinheiro de volta.
                        </Typography>
                     </Box>
                  </Grid>
                  <Grid item md='8'>
                     <Grid container justifyContent='center' spacing={10}>

                        <Grid item md={9}>
                           <Box className={classes.box} p={12}>
                              <Box mb={4} textAlign='center'>
                                 <Typography component='h4' variant='h2'>
                                    Super Condição Limitada
                                 </Typography>
                              </Box>
                              <Box mb={8} textAlign='center'>
                                 <Typography component='p' variant='body1'>
                                    O acesso que era anual agora é até a sua aprovação. Chegou a sua hora de voar alto.
                                 </Typography>
                              </Box>
                              <Box mb={1} textAlign='center'>
                                 <Typography component='p' variant='body1'>
                                    À vista por <Typography component='span' variant='h3'>R$ 238,00</Typography>
                                 </Typography>
                              </Box>
                              <Box mb={1} textAlign='center'>
                                 <Grid container justifyContent='center' alignItems='center' alignContent='center' spacing={0}>
                                    <Grid item>
                                       <Icon icon="uil:fire" className={classes.iconFire} />
                                    </Grid>
                                    <Grid item>
                                       <Typography component='span' variant='h2'>
                                          ou 12x de
                                          <span className={classes.primary}> R$ 23,80</span>
                                       </Typography>
                                    </Grid>
                                    <Grid item>
                                       <Icon icon="uil:fire" className={classes.iconFire} />
                                    </Grid>
                                 </Grid>
                              </Box>
                              <Box mb={8} textAlign='center'>
                                 <Typography component='p' variant='body2'>
                                    Por tempo limitado
                                 </Typography>
                              </Box>
                              <Box textAlign='center'>
                                 <Button variant='contained' href='https://sun.eduzz.com/2138637'
                                    classes={{
                                       root: classes.button,
                                       label: classes.buttonLabel,
                                    }}>Pagar agora
                                 </Button>
                              </Box>
                           </Box>
                        </Grid>

                     </Grid>
                  </Grid>
                  <Grid item md='5'>
                     <Box mt={{ xs: 16 }} mb={{ xs: 8 }} px={{ xs: 0 }} className={classes.white} textAlign='center'>
                        <Typography component='p' variant='h5'>
                           <span className={classes.primary}>Dê o primeiro passo rumo à sua aprovação, </span>
                           conquiste sua independência e realize seu sonhe de ser PRF!
                        </Typography>
                     </Box>
                  </Grid>
               </Grid>
            </Container>
         </Box>



         <Footer />

      </Grid >
   );
}